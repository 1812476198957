import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { GeniiSharedServiceService } from '../shared-services/genii-shared-service.service';
import { environment } from '@root/environments/environment';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupGuard implements CanActivate {
  constructor(private geniiSharedService: GeniiSharedServiceService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const routePath = state.url.split('?')[0].slice(1); // Removes query parameters and first '/'
    
    return this.geniiSharedService.canActivateItem(routePath).pipe(
      tap(canActivate => {
        console.log('canActivateItem ' + routePath + ':', canActivate);
        if (!canActivate) {
          this.router.navigate(['']); // Redirects if no access
        }
      })
    );
  }
}