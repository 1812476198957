import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, filter, map, Observable, Subject, take } from 'rxjs';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';
import { environment } from '@root/environments/environment';
import { AppPaths } from '../app.routes';

export type AuthorizedGroupType = {
  path: AppPaths;
  groups: string[];
};

export type UserGroups = {
  document_data_extraction_admin: string;
  document_data_extraction: string;
  idlt_connect: string;
  canoe_data_extraction: string;
  reports: string;
};

@Injectable({
  providedIn: 'root'
})
export class GeniiSharedServiceService {
  private userGroupsSubject = new BehaviorSubject<string[] | null>(null);
  userGroups = this.userGroupsSubject.asObservable();
  private eventSubject = new Subject<any>();
  event$ = this.eventSubject.asObservable();
  private accountInfo;
  authorizedGroups: AuthorizedGroupType[] = [];

  constructor(
    private msalService: MsalService
  ) { }

  emitEvent(eventData: any) {
    this.eventSubject.next(eventData);
  }

  // Initialize MSAL and wait for it to finish
  async initializeMsal(): Promise<void> {
    const msalInstance: IPublicClientApplication = this.msalService.instance;
    try {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        await msalInstance.initialize();
        console.log('MSAL initialized successfully');
        this.checkAccount();
      } else {
        console.log('MSAL already initialized');
        await msalInstance.initialize();
        this.msalService.instance.setActiveAccount(account);
        this.saveAccountInfo(account);
      }
    } catch (error) {
      console.error('MSAL initialization failed:', error);
    }
  }

  checkAccount(): void {
    console.log('checkAccount');
    this.msalService.instance.handleRedirectPromise().then((result: AuthenticationResult | null) => {
      console.log('handleRedirectPromise...');
      if (result !== null) {
        console.log('Login from handleRedirectPromise successful:', result);
        this.msalService.instance.setActiveAccount(result.account);
        this.saveAccountInfo(result.account);
      } else {
        this.getAccountOrSSO();
      }
    }).catch(error => {
      console.error('Error during loginRedirect:', error);
    });
  }

  getAccountOrSSO() {
    console.log('getAccountOrSSO...');
    console.log('getActiveAccount...');
    const account = this.msalService.instance.getActiveAccount();
    if(account) {
      console.log('Active account found:', account);
      this.saveAccountInfo(account);
    }
    else {
      // try silent SSO
      this.msalService.ssoSilent({
        scopes: ['user.read']
      }).subscribe({
        next: (silentResponse) => {
          console.log('Silent SSO successful:', silentResponse);
          this.msalService.instance.setActiveAccount(silentResponse.account);
          console.log('setActiveAccount:', silentResponse.account);
          this.saveAccountInfo(silentResponse.account);
        },
        error: (error) => {
          console.log('Silent SSO failed:', error);
          if (this.msalService.instance.getAllAccounts().length === 0) {
            console.log('Silent SSO failed:', error);
            if (error instanceof InteractionRequiredAuthError) {
              // Start interactive flow when silent sso fails because multiple identities
              console.log('Interaction required, triggering login');
              this.msalService.loginRedirect({
                scopes: ['user.read'],
                prompt: 'select_account' // Force account selection
              });
            } else {
              console.error('Unexpected error:', error);
            }
          }
        }
      });
    }
  }

  saveAccountInfo(account: AccountInfo) {
    this.accountInfo = account;
    console.log('accountInfo saved:', this.accountInfo);
    const idTokenClaims = account.idTokenClaims;
    if (idTokenClaims) {
      const groups = idTokenClaims['groups'] as string[] | null;;
      this.userGroupsSubject.next(groups);
      console.log('User groups from token:', groups);
    }
    this.getRefreshedAccessToken(account);
  }

  getUserID(): string {
    return this.accountInfo ? this.accountInfo.username : '';
  }

  getRefreshedAccessToken(account: AccountInfo) {
    console.log('getRefreshedAccessToken initiated...');
    const request = {
      scopes: ['User.Read'],
      account: account,
      forceRefresh: false // If need to force token update
    };

    this.msalService.instance.acquireTokenSilent(request)
      .then((result: AuthenticationResult) => {
        const accessToken = result.accessToken;
        console.log('token obtained:', accessToken);
        
        this.accountInfo = result.account;
        console.log('accountInfo saved:', this.accountInfo);
        const idTokenClaims = account.idTokenClaims;
        if (idTokenClaims) {
          const groups = idTokenClaims['groups'] as string[] | null;;
          this.userGroupsSubject.next(groups);
          console.log('User groups from token:', groups);
          console.log('environment.UserGroups:', environment.UserGroups);
        }
        // Here we could call Microsoft Graph with obtained token
        // this.callMicrosoftGraph(accessToken);
      })
      .catch((error) => {
        console.error('Error getting the token:', error);
        if (error instanceof InteractionRequiredAuthError) {
          // User interaction needed
          this.msalService.acquireTokenRedirect(request);
        } else {
          // Handle more errors
        }
      });
  }

  /** Fills userGroups with all groups - Use this only for local environment !! */
  fillGroups() {
    console.log('fillGroups');
    setTimeout(() => {
      if(!environment.authentication) {
        console.log('environment.UserGroups:', environment.UserGroups);
        this.userGroupsSubject.next(Object.values(environment.UserGroups));
        // this.userGroupsSubject.next([UserGroups.idlt_connect]);
      }
    }, 2000); // The timeout emulates the behavior when getting the groups from azure
  }

  getAuthorizedGroups() {
    return this.authorizedGroups;
  }

  /**
   * Sets the relationship between the path and the groups authorized to access it.
   */
  setAuthorizedGroups() {
    this.authorizedGroups = [
      { path: AppPaths.iDLTConnect_menuItem, groups: [
        environment.UserGroups.idlt_connect,
      ] },
      { path: AppPaths.admin_menuItem, groups: [
        environment.UserGroups.document_data_extraction_admin
      ] },
      { path: AppPaths.canoe_data_extraction, groups: [
        environment.UserGroups.canoe_data_extraction,
      ] },
      { path: AppPaths.ai_report, groups: [
        environment.UserGroups.document_data_extraction,
      ] },
      { path: AppPaths.data_extraction, groups: [
        environment.UserGroups.document_data_extraction,
      ] },
      { path: AppPaths.report, groups: [
        environment.UserGroups.reports,
      ] },
      { path: AppPaths.report_detail, groups: [
        environment.UserGroups.reports,
      ] },
      { path: AppPaths.fund_management, groups: [
        environment.UserGroups.idlt_connect,
      ] },
      { path: AppPaths.subscription_management, groups: [
        environment.UserGroups.idlt_connect,
      ] },
      { path: AppPaths.capital_event_management, groups: [
        environment.UserGroups.idlt_connect,
      ] },
      { path: AppPaths.admin_document_type, groups: [
        environment.UserGroups.document_data_extraction_admin
      ] },
      { path: AppPaths.admin_prompt, groups: [
        environment.UserGroups.document_data_extraction_admin
      ] },
      
    ];
  }

  /** Verify if user has any of the authorizedGroups groups for this path */
  canActivateItem(path: string): Observable<boolean> {
    const pathAuthorizedGroups = this.authorizedGroups.find((entry) => entry.path === path);
    return this.userGroups.pipe(
      filter(groups => !!groups), // Wait until groups are not null
      take(1), // Just takes the first emitted value
      map(userGroups => 
        (pathAuthorizedGroups && pathAuthorizedGroups.groups.some(group => userGroups!.includes(group))) ? true : false)
    );
  }
}
